import React from 'react'

const CardTitle = ({ title, description, action }) => {
  return (
    <div className="mb-2">
      <h4 className="d-flex justify-content-between">
        <strong>{title}</strong>
        {action}
      </h4>
      <p className="font-size-14 text-secondary" hidden={!description}>
        {description}
      </p>
    </div>
  )
}

export default CardTitle
